import React, { useEffect, useState } from "react";

import Loading from "../common/Loading";
import { RenderPageTemplate } from "../mapping";
import { PersonnelApi, useAuth } from "@unity/components";
import config from "../config";

export default function PersonnelView({ context, route, history }) {
  const { checkPermission } = useAuth();

  const [data, setData] = useState(false);

  const read = checkPermission(
    `read_${config.resourceName}_${config.defaultRole}`
  );
  const create = checkPermission(
    `create_${config.resourceName}_${config.defaultRole}`
  );
  const edit = checkPermission(
    `update_${config.resourceName}_${config.defaultRole}`
  );

  const getPersonnelSingle = async () => {
    const res = await PersonnelApi.getPersonnelSingle(route.match.params.id);
    setData(res.data);
  };

  const handleExtensionChange = (name, value) => {};

  const handleContactChange = (name, value) => {};

  useEffect(() => {
    getPersonnelSingle();
  }, []);

  const handleChange = (name, value) => {};

  const RenderPage = RenderPageTemplate(context.auth.app_uuid, "view");

  if (data) {
    return (
      <>
        <RenderPage
          data={data}
          handleChange={handleChange}
          type="view"
          handleContactChange={handleContactChange}
          handleExtensionChange={handleExtensionChange}
          context={context}
          history={history}
          route={route}
          read={read}
          create={create}
          edit={edit}
        />
      </>
    );
  } else {
    return <Loading />;
  }
}
