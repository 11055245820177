import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import { AuthProvider } from "@unity/components";
import Personnel from "./pages/index";
import PersonnelView from "./pages/view";
import PersonnelEdit from "./pages/edit";
import PersonnelCreate from "./pages/create";
import config from "./config";

export default function Root(props) {
  let context = { ...props };
  let history = useHistory();
  const personnelAccess = props.auth.access.personnel["A"];

  return (
    <AuthProvider module_alias={config.resourceName} {...props}>
      <Router>
        <Route
          render={({ location }) => (
            <TransitionGroup className="transition-group">
              <CSSTransition key={location.key} timeout={300} classNames="fade">
                <section className="route-section">
                  <Switch location={location}>
                    <Route path="/location" exact component={Location} />

                    {personnelAccess && (
                      <Route
                        path="/personnel/index"
                        exact
                        component={(route) => (
                          <Personnel
                            route={route}
                            history={history}
                            context={context}
                          />
                        )}
                      />
                    )}

                    {personnelAccess && (
                      <Route
                        path="/personnel/view/:id"
                        exact
                        component={(route) => (
                          <PersonnelView
                            route={route}
                            history={history}
                            context={context}
                          />
                        )}
                      />
                    )}
                    {personnelAccess && (
                      <Route
                        path="/personnel/edit/:id"
                        exact
                        component={(route) => (
                          <PersonnelEdit
                            route={route}
                            context={context}
                            history={history}
                          />
                        )}
                      />
                    )}
                    {personnelAccess && (
                      <Route
                        path="/personnel/create"
                        exact
                        component={(route) => (
                          <PersonnelCreate
                            route={route}
                            history={history}
                            context={context}
                          />
                        )}
                      />
                    )}
                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </AuthProvider>
  );
}
