import React, { useContext, useState } from "react";
import { RenderComponent, RenderExtensions } from "../../mapping";
import { useAuth } from "@unity/components";

export default function PersonnelForm(props) {
  const { auth } = useAuth();

  const FormComponent = RenderComponent(auth.app_uuid);
  const FormExtension = RenderExtensions(auth.access);

  return FormComponent && FormExtension ? (
    <div>
      <FormComponent {...props} />
      {props.type !== "create" &&
        FormExtension.map((Ext, key) => {
          return <Ext {...props} key={key} />;
        })}
    </div>
  ) : (
    <p>Loading Form...</p>
  );
}
