import React from "react";
import { InputLabel, MenuItem, Select, TextField } from "@mui/material";

//form sets init state
//update creates array of updated values

export default function PersonnelStandardForm(props) {
  return (
    <div className="block">
      <div className="container-fluid">
        <div className="form-row">
          <div className="form-group col-lg-12">
            <h3>Personnel</h3>
          </div>
        </div>
        {props.type == "create" && (
          <Select
            label="Choose a user to add to this system"
            defaultValue={1}
            onChange={(e) => props.handleUserSelect(e)}
          >
            <MenuItem value={1}>Please Choose...</MenuItem>
            {props.users &&
              props.users.map((item, key) => {
                return (
                  <MenuItem key={key} value={key}>
                    {item.email}
                  </MenuItem>
                );
              })}
          </Select>
        )}

        <div className="form-row">
          <div className="form-group col-lg-4">
            <TextField
              label="Name"
              name="name"
              value={props.data.name || ""}
              disabled
              fullWidth={true}
            ></TextField>
          </div>
          <div className="form-group col-lg-4">
            <TextField
              label="Telephone"
              name="extension"
              value={props.data.extension || ""}
              disabled
              fullWidth={true}
              helpertext={"office extension number"}
            ></TextField>
          </div>
          <div className="form-group col-lg-4">
            <TextField
              label="Mobile"
              name="mobile"
              value={props.data.mobile || ""}
              disabled
              fullWidth={true}
              helpertext={"company mobile number"}
            ></TextField>
          </div>
        </div>
        <div
          className="form-row"
          style={{ display: "flex", alignItems: "flex-end" }}
        >
          <div className="form-group col-lg-4">
            <TextField
              label="Email Address"
              value={props.data.email || ""}
              name="email"
              disabled
              fullWidth={true}
              helpertext={"comopany email address"}
            ></TextField>
          </div>
          <div className="form-group col-lg-4">
            <TextField
              label="Job Title"
              value={props.data.position || ""}
              disabled
              fullWidth={true}
              name="position"
              helpertext={"job title / position"}
            ></TextField>
          </div>
          {props.type != "view" ? (
            <div className="form-group col-lg-4">
              <InputLabel style={{ fontSize: 12, margin: 2 }}>
                User Permission
              </InputLabel>
              <Select
                value={props.data.role_id || ""}
                fullWidth={true}
                name="permission"
                onChange={(e) => props.handleRoleChange(e.target.value)}
                helpertext={"Access level"}
              >
                {props.roles.map((item, key) => {
                  return (
                    <MenuItem key={key} value={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          ) : (
            <div className="form-group col-lg-4">
              <TextField
                fullWidth={true}
                label="Permission Level"
                value={props.data.role || ""}
              />
            </div>
          )}
        </div>
        {props.manager ? (
          <div className="form-row">
            <div className="form-group col-lg-4">
              <TextField
                fullWidth={true}
                label="Manager"
                disabled={true}
                value={props.manager}
              />
            </div>
          </div>
        ) : props.manager !== 0 ? (
          <p>Loading Manager...</p>
        ) : (
          <p></p>
        )}
        {props.context.auth.line_manager ? (
          <div className="form-row">
            <div className="form-group col-lg-4">
              <TextField
                type="number"
                name="cost_to_business"
                defaultValue={props.data.cost_to_business}
                min="0"
                step="0.01"
                label="Rate for Business"
                disabled={props.type === "view"}
                fullWidth
                onChange={(e) => {
                  props.handleContactChange(e.target.name, e.target.value);
                }}
              />
            </div>
            <div className="form-group col-lg-4">
              <TextField
                type="number"
                name="cost_to_customer"
                defaultValue={props.data.cost_to_customer}
                min="0"
                step="0.01"
                label="Rate for Customers"
                disabled={props.type === "view"}
                fullWidth
                onChange={(e) => {
                  props.handleContactChange(e.target.name, e.target.value);
                }}
              />
            </div>
          </div>
        ) : (
          <></>
        )}

        {props.formExtension}

        {props.type === "edit" && props.del ? (
          <button
            onClick={() => props.handleDeregister()}
            className="btn btn-danger"
          >
            Deactivate
          </button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
